<template>
  <div id="regimen-template-app" class="form-horizontal">
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
      <div class="title-bar pull-left center-float">
        <h1 id="heading" class="dark-text">
          {{ $t("_add_medication_template") }}
        </h1>
      </div>
    <form class="form-control">
          <Input v-model="localTemplate.name" label="Name of the Template" class="form-input" placeHolderText="Enter template name"
          isRequired orientation="v"/>
          <Input v-model="localTemplate.email" label="Description" class="form-input" placeHolderText="Enter description" orientation="v"/>
          <Input v-model="localTemplate.associatedDiseases" label="Associated Diseases" class="form-input" placeHolderText="Enter Associated Diseases" orientation="v"/>
          <Input v-model="localTemplate.duration" label="Treatment Duration" class="form-input" placeHolderText="Enter treatment duration" orientation="v"/>
          <Input v-model="localTemplate.medication" label="Add Medication" class="form-input" placeHolderText="Enter medication" orientation="v"/>
    </form>
    <div>
        <Button :label="$t('_add_medication_template')" v-on:click='this.successfulClick'/>
    </div>
  </div>
</template>

<script>
import { defaultToast, ToastType } from '../../../../utils/toastUtils'
import html2canvas from 'html2canvas'
import { mapActions } from 'vuex'
import Breadcrumbs from '../../../shared/components/Breadcrumbs.vue'
import Input from '../../../shared/components/Input.vue'
import Button from '../../../shared/components/Button.vue'

window.html2canvas = html2canvas

export default {
  name: 'RegimenTemplate',
  data: self => ({
    formActive: true,
    addedPatientId: '',
    hasVot: false,
    votUserName: '',
    votUserPin: '',
    loginUserName: '',
    loginUserPin: '',
    breadcrumbs: [
      {
        label: 'Dashboard',
        link: '/dashboard/overview',
        class: ''
      },
      {
        label: '_add_medication_template',
        link: '',
        class: 'active'
      }
    ],
    localTemplate: {
      name: '',
      description: '',
      associatedDiseases: '',
      duration: '',
      medication: ''
    }
  }),
  components: {
    Breadcrumbs,
    Input,
    Button
  },
  methods: {
    successfulClick () {
      defaultToast(ToastType.Success, 'Template added successfully!')
    },
    ...mapActions(['setGetFormUrlParams'])
  }
}
</script>

<style lang="scss" scoped>
#regimen-template-app {
  background-color: var(--background-bg-color);
}
* {
  margin: revert;
  box-sizing: unset;
}
.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-container-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
.patient-success-icon{
  background: url("~@/assets/check_circle_green.svg") no-repeat;
  height: 60px;
  width: 60px;
  background-size: 60px, 60px;
}
.text-center{
  text-align: center;
}
.margin-vot-right{
  margin-right: 200px;
  margin-left: 100px;
}
.form-control{
  padding-bottom: 20px;
  padding-left: 10px;
  padding-top: 10px;
  box-shadow: none;
}
.form-input{
  padding: 5px;
  box-shadow: none;
  width: 200px;
}
</style>
